
import { defineComponent, computed } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsNotifications from '@web/components.v2/Settings/Notifications/index.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'

import i18n from '@web/plugins/i18n'
import 'sweetalert2/dist/sweetalert2.min.css'
import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'

export default defineComponent({
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsHeaderMain,
    AsMenu,
    AsFooter,
    AsSpacer,
    AsNotifications,
    AsBreadcrumb
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const title = i18n.global.t('notificationSettings')
    const menu = computed(() => store.getters['menu/general'])

    useMeta({
      title
    })

    function goBack () {
      router.push({ name: 'userSettings' })
    }

    return {
      title,
      menu,
      goBack
    }
  }
})
