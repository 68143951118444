
import { computed, defineComponent, ref } from 'vue'
import { MqResponsive } from 'vue3-mq'

import SettingsContent from '@web/components.v2/Settings/Content.vue'
import AsSwitch from '@web/components/AsSwitch.vue'

import useEmailSubscribe from '@web/composition/useEmailSubscribe'
import usePushSubscribe from '@web/composition/usePushSubscribe'
import { IS_MOBILE } from '@web/common/config'

export default defineComponent({
  name: 'Notifications',
  components: {
    MqResponsive,
    SettingsContent,
    AsSwitch
  },
  setup () {
    const {
      subscribed: isPushSubcribed,
      isOneSignalEnable: isShowPushSubscribeBtn,
      btnText: pushSubscribeBtnText,
      toggleSubscribe: togglePushSubscribe
    } = usePushSubscribe()

    const {
      subscribed: isEmailSubcribed,
      isSendingEmail,
      btnText: emailSubscribeBtnText,
      toggleSubscribe: toggleEmailSubscribe
    } = useEmailSubscribe()

    const disabledRemindTraining = computed(() => !isPushSubcribed.value && !isEmailSubcribed.value)

    const isSmsSubscribed = ref<boolean>(false)
    function toggleSmsSubscribe () {
      isSmsSubscribed.value = !isSmsSubscribed.value
    }

    const isWhatsappSubscribed = ref<boolean>(false)
    function toggleWhatsappSubscribe () {
      isWhatsappSubscribed.value = !isWhatsappSubscribed.value
    }

    return {
      isPushSubcribed,
      isShowPushSubscribeBtn,
      pushSubscribeBtnText,
      togglePushSubscribe,
      isEmailSubcribed,
      isSendingEmail,
      emailSubscribeBtnText,
      toggleEmailSubscribe,
      disabledRemindTraining,
      isMobile: IS_MOBILE,

      isSmsSubscribed,
      toggleSmsSubscribe,

      isWhatsappSubscribed,
      toggleWhatsappSubscribe
    }
  }
})
