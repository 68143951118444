import { computed, ref } from 'vue'
import { User } from '@web/store/types/modules/user'
import { useStore } from '@web/store'
import i18n from '@web/plugins/i18n'

function useEmailSubscribe () {
  const store = useStore()
  const user = computed<User>(() => store.getters['user/user'])
  const isSendingEmail = ref(false)

  const subscribed = computed(() => !user.value.disableNotification)
  const btnText = computed<string>(() => !subscribed.value ? i18n.global.t('Subscribe') : i18n.global.t('Unsubscribe'))

  async function toggleSubscribe () {
    if (isSendingEmail.value) {
      return
    }

    isSendingEmail.value = true

    store.dispatch('user/notification', {
      disableNotification: !user.value.disableNotification
    })
      .finally(() => {
        isSendingEmail.value = false
      })
  }

  return {
    subscribed,
    isSendingEmail,
    btnText,
    toggleSubscribe
  }
}

export default useEmailSubscribe
