import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "acNotifications" }
const _hoisted_2 = { class: "acNotifications_title" }
const _hoisted_3 = { class: "acNotifications_content" }
const _hoisted_4 = {
  key: 0,
  class: "acNotifications_item"
}
const _hoisted_5 = { class: "acNotifications_item_wrapper" }
const _hoisted_6 = { class: "acNotifications_label" }
const _hoisted_7 = { class: "acNotifications_item" }
const _hoisted_8 = { class: "acNotifications_item_wrapper" }
const _hoisted_9 = { class: "acNotifications_label" }
const _hoisted_10 = { class: "acNotifications_item" }
const _hoisted_11 = { class: "acNotifications_item_wrapper" }
const _hoisted_12 = { class: "acNotifications_label" }
const _hoisted_13 = { class: "acNotifications_item" }
const _hoisted_14 = { class: "acNotifications_item_wrapper" }
const _hoisted_15 = { class: "acNotifications_label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_switch = _resolveComponent("as-switch")!
  const _component_settings_content = _resolveComponent("settings-content")!

  return (_openBlock(), _createBlock(_component_settings_content, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_mq_responsive, { target: "desktop" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('notificationSettings')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.isShowPushSubscribeBtn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('siteNotifications')), 1),
                  _createVNode(_component_as_switch, {
                    modelValue: _ctx.isPushSubcribed,
                    "onUpdate:modelValue": _ctx.togglePushSubscribe
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('emailNotifications')), 1),
              _createVNode(_component_as_switch, {
                loading: _ctx.isSendingEmail,
                modelValue: _ctx.isEmailSubcribed,
                "onUpdate:modelValue": _ctx.toggleEmailSubscribe
              }, null, 8, ["loading", "modelValue", "onUpdate:modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('smsNotifications')), 1),
              _createVNode(_component_as_switch, {
                modelValue: _ctx.isSmsSubscribed,
                "onUpdate:modelValue": _ctx.toggleSmsSubscribe
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('whatsappNotifications')), 1),
              _createVNode(_component_as_switch, {
                modelValue: _ctx.isWhatsappSubscribed,
                "onUpdate:modelValue": _ctx.toggleWhatsappSubscribe
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}